<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name mb-0">
          {{ user && user.name }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>

      <b-avatar size="40" :text="avatarInitialis()" :variant="`light-primary`" />
    </template>

<!--    <b-dropdown-item link-class="d-flex align-items-center">-->
<!--      <feather-icon size="16" icon="UserIcon" class="mr-50" />-->
<!--      <span>Profile</span>-->
<!--    </b-dropdown-item>-->

<!--    <b-dropdown-divider />-->

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BAvatar,
    },
    data() {
        return {
            // userData: JSON.parse(localStorage.getItem('userData')),
            userData: {
                fullName: 'John Doe',
            },
            avatarText,
        };
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },

    methods: {
        ...mapActions({
            logoutAction: 'auth/logout',
        }),

        async logout() {
            document.getElementById('app').style.display = 'none';
            document.getElementById('loading-bg').style.display = 'block';

            this.$store.commit('auth/SET_AUTHENTICATED', false);
            this.$store.commit('auth/SET_CAMPAIGN', false);

            await this.logoutAction();

            document.getElementById('app').style.display = 'block';
            document.getElementById('loading-bg').style.display = 'none';
            this.$router.push({ name: 'login' });
        },

        avatarInitialis() {
            if (this.user) {
                const nameArray = this.user.name.split(' ');
                return `${nameArray[0][0]}${nameArray[nameArray.length - 1][0]}`;
            }
            return '';
        },

    },
};
</script>
