export default [

    {
        title: 'Campaign',
        route: 'campaigns',
        icon: 'add-paper',
    },

    {
        title: 'Company',
        icon: 'company',
        route: 'company',
        children: [
            {
                title: 'Leads',
                route: 'company.leads',
                icon: 'users',
            },
            {
                title: 'Approached',
                route: 'company.approached',
                icon: 'send',
            },
            {
                title: 'Response Received',
                route: 'company.response-received',
                icon: 'source-of-fund',
            },
            {
                title: 'Acquired',
                route: 'company.acquired',
                icon: 'available',
            },
        ],
    },

    {
        title: 'User',
        route: 'users',
        icon: 'users',
    },
];
